import React from "react";
import { graphql } from "gatsby";
import { MDXRenderer } from "gatsby-plugin-mdx";
import Layout from "../../components/layout";
import styled, { css } from "styled-components";
import useSiteMetadata from "../../hooks/use-site-metadata";
import Image from "../../components/common/Image";
import IconButton from "../../components/form/IconButton";
import { name } from "../../../config";
import { isVideo } from "../../utils/helpers/file";
import { Flex } from "../../styles/global-styles";
import { fontFamily, colors, breakpoints } from "../../../theme";
import { StaticImage } from "gatsby-plugin-image";
import {
  FiFacebook,
  FiTwitter,
  FiLinkedin,
  FiLink,
  FiChevronLeft,
} from "react-icons/fi";
import useMediaQuery from "../../hooks/use-media-query";
import Seo from "../../components/SEO";
import { navigate } from "gatsby";
import toast from "react-hot-toast";
import { safeNavigator } from "../../utils/helpers/ssr";

// Query

const hasClipboard = safeNavigator.clipboard !== undefined;

export const query = graphql`
  query ($id: String!) {
    mdx(id: { eq: $id }) {
      body
      tableOfContents
      excerpt(pruneLength: 250)
      frontmatter {
        published
        slug
        title
        description
        tags
        series
        tint_color
        cover_src
        date(formatString: "Do MMMM, YYYY")
        read_time
      }
    }
  }
`;

const SocialMediaIcons = styled(Flex)`
  display: flex;
  align-items: center;

  ${props =>
    !props.isMobile &&
    css`
      width: fit-content;
      position: absolute;
      top: 5.5%;
      left: 5%;
      @media only screen and (min-width: 900px) {
        left: 7%;
      }

      @media only screen and (min-width: 1100px) {
        left: 10%;
      }
    `}
`;

const ShareText = styled.span`
  font-family: ${fontFamily.regular};
  margin-bottom: 8px;
`;

const SocialMediaButtons = ({ shareLinks, isMobile }) => {
  return (
    <SocialMediaIcons column={!isMobile} isMobile={isMobile}>
      {!isMobile && <ShareText>Share</ShareText>}
      <IconButton
        href={shareLinks.twitter}
        target="_blank"
        iconColor="#000"
        bgColor="#888"
        ariaLabel="Share on Twitter"
      >
        <FiTwitter />
      </IconButton>
      <IconButton
        href={shareLinks.facebook}
        target="_blank"
        iconColor="#000"
        bgColor="#888"
        ariaLabel="Share on Facebook"
      >
        <FiFacebook />
      </IconButton>
      <IconButton
        href={shareLinks.linkedin}
        target="_blank"
        iconColor="#000"
        bgColor="#888"
        ariaLabel="Share on Linkedin"
      >
        <FiLinkedin />
      </IconButton>
      {hasClipboard && (
        <IconButton
          iconColor="#000"
          bgColor="#888"
          onClick={() => {
            copyLink(shareLinks.blogLink);
            toast.success("Link copied!");
          }}
          ariaLabel="Copy to Clipboard"
        >
          <FiLink />
        </IconButton>
      )}
    </SocialMediaIcons>
  );
};

const copyLink = link => {
  navigator.clipboard.writeText(link);
};

const BlogContainer = styled.div`
  width: calc(100vw - 48px);
  margin: 40px auto 120px;

  img,
  video {
    width: 100%;
    height: auto;
  }

  @media only screen and (min-width: ${breakpoints.tablet}) {
    width: 65%;
    max-width: 800px;
    margin: 40px auto 120px;
  }
  @media only screen and (min-width: 900px) {
    width: 60%;
  }
`;

const BlogTitle = styled.h2`
  margin: 0 0 16px;
  font-family: ${fontFamily.extraBold};
  font-size: 24px;
  // font-size: 28px;
  // width: 90%;

  @media only screen and (min-width: ${breakpoints.tablet}) {
    font-size: 44px;
    margin: 0 0 32px;
  }
`;
const BlogHelperText = styled.span`
  color: ${colors.grey.darkMedium};
  // margin-right: 12px;
`;
const Category = styled.h4`
  display: inline-block;
  color: ${colors.grey.darkMedium};
  margin: 16px 0 8px;
  font-family: ${fontFamily.regular};
  font-size: 12px;

  @media only screen and (min-width: ${breakpoints.tablet}) {
    text-transform: uppercase;
    color: #dc684a;
    margin: 40px 0 8px;
  }
`;

const Video = styled.video`
  width: 100%;
  height: auto;
  border-radius: 6px;
  // box-shadow: 2px 2px 12px rgba(0, 0, 0, 0.1);
  @media only screen and (min-width: ${breakpoints.tablet}) {
    border-radius: 12px;
  }
`;

const BlogContentContainer = styled.div`
  p {
    letter-spacing: -0.003em;
    line-height: 28px;
    margin-top: 1.16em;
    font-size: 16px;
    // font-size: 21px;
    margin-bottom: -0.46em;
    color: rgba(41, 41, 41, 1);

    @media only screen and (min-width: ${breakpoints.tablet}) {
      margin-top: 1.56em;
      line-height: 32px;
      margin-top: 2em;
      font-size: 20px;
    }
  }

  h3 {
    letter-spacing: 0;
    line-height: 36px;
    margin-top: 1.95em;
    font-size: 18px;
    margin-bottom: -0.28em;
    font-family: ${fontFamily.bold};

    @media only screen and (min-width: ${breakpoints.tablet}) {
      font-size: 30px;
    }
  }

  ol,
  ul {
    padding: 0;
    list-style: none;
    list-style-image: none;
  }

  li {
    margin-top: 0.86em;
    letter-spacing: -0.003em;
    line-height: 32px;
    padding-left: 0px;
    margin-left: 20px;
    list-style-type: decimal;
    margin-bottom: -0.46em;
    font-size: 16px;

    @media only screen and (min-width: ${breakpoints.tablet}) {
      font-size: 20px;
      margin-left: 30px;
    }
  }
`;
const Name = styled.span`
  margin-left: 8px;
  font-size: 12px;
  font-family: ${fontFamily.medium};

  @media only screen and (min-width: ${breakpoints.tablet}) {
    font-size: 16px;
  }
`;

const SubTitleContainer = styled(Flex)`
  padding: 0 12px 0 4px;
  margin-bottom: 20px;

  @media only screen and (min-width: ${breakpoints.tablet}) {
    margin-bottom: 28px;
  }
`;

const BlogFooter = styled.div``;
const ShareBlogFooter = styled(Flex)`
  border-bottom: 1px solid ${colors.grey.medium};
  padding: 20px 13px 8px;
  margin-top: 36px;

  @media only screen and (min-width: ${breakpoints.tablet}) {
    padding: 30px 20px;
    margin-top: 100px;
  }
`;
const AuthorBlogFooter = styled.div`
  margin-top: 20px;
  display: flex;
  padding: 0 12px;
  align-items: flex-start;

  @media only screen and (min-width: ${breakpoints.tablet}) {
    margin-top: 30px;
    padding-left: 30px;
  }
`;

const AuthorBlogFooterContent = styled(Flex)`
  margin-left: 20px;
`;

// const AuthorBlog

const ShareFooterText = styled.span`
  font-size: 16px;
  font-family: ${fontFamily.bold};

  @media only screen and (min-width: ${breakpoints.tablet}) {
    font-size: 20px;
  }
`;

const AuthorLabel = styled.span`
  margin-top: 4px;
  font-size: 14px;

  font-family: ${fontFamily.medium};
  @media only screen and (min-width: ${breakpoints.tablet}) {
    margin-top: 8px;
    font-size: 16px;
  }
`;

const AuthorName = styled.span`
  margin: 4px 0 2px;
  font-family: ${fontFamily.bold};

  @media only screen and (min-width: ${breakpoints.tablet}) {
    margin: 8px 0 4px;
  }
`;

const AuthorDescription = styled.p`
  width: 100%;
  color: ${colors.grey.darkMedium};
  margin: 2px 0;
  font-size: 14px;

  @media only screen and (min-width: 500px) {
    width: 70%;
    font-size: 16px;
  }
`;

const Blog = ({ data }) => {
  const { siteUrl } = useSiteMetadata();
  // console.log({ data });

  const {
    body,
    frontmatter: {
      published,
      slug,
      title,
      description,
      tags,
      series,
      tint_color,
      cover_src,
      date,
      read_time,
    },
  } = data.mdx;

  // console.log({ read_time, tableOfContents, excerpt, slug, data });

  const randomData = {
    formattedTime: `${read_time} ${read_time > 1 ? "mins" : "min"}`,
    series,
    date,
    slug,
    blogLink: `${siteUrl}/blog/${slug}`,
    title,
    tags,
    tint_color,
    // originalName,
    cover_src,
    published,
    description,
  };
  // console.log({ randomData });

  const isMobile = !useMediaQuery(`(min-width: ${breakpoints.tablet} )`);

  const goBack = () => {
    navigate(-1);
  };

  // data
  // DONE: Data related to blog
  // xx Formatted data in minutes
  // xx Series
  // xx Date
  // xx slug
  // xx Complete link
  // xx cover image
  // xx heading
  // xx tint-color
  // xx Tags
  // -- Also Read data
  // DONE: Personal Information
  // TODO: Also read data

  // TODO: Components
  // -- Social Media Icon Button
  // -- Cover Wrapper
  // -- Content Container
  // -- Personal Information
  // -- Go To Top Button
  // -- Scroll Progress
  // -- Tag Container & Tag Component
  // LATER SECTION
  // -- Read ALso Section

  // TODO: Mobile Screen UI
  // TODO: Desktop Screen UI
  // TODO: Responsiveness

  // TODO: Functionality
  // -- Back Button
  // -- Scroll Progress
  // -- Go To Top Button

  /* style={{
          display: "flex",
          flexDirection: "column",
          position: inView ? "fixed" : "absolute",
          width: "fit-content",
          top: "60%",
          left: "10%",
        }}
        ref={ref}      
  */

  const websiteLink = process.env.GATSBY_WEBSITE_URL;

  const blogLink = `${websiteLink}blog/${slug}`;

  const shareLinks = {
    blogLink,
    facebook: `https://www.facebook.com/sharer/sharer.php?u=${blogLink} `,
    twitter: `https://twitter.com/intent/tweet?url=${blogLink} &text=${title}`,
    linkedin: `https://www.linkedin.com/shareArticle?mini=true&url=${blogLink} `,
  };

  return (
    <Layout>
      <Seo title={title} description={description} image={cover_src} article />
      {!isMobile && (
        <SocialMediaButtons shareLinks={shareLinks} isMobile={false} />
      )}
      <BlogContainer>
        {/* Back Button */}
        {isMobile && (
          <Flex spaceBetween>
            <IconButton
              bgColor="#bbb"
              iconColor="#000"
              style={{ marginLeft: "-12px" }}
              onClick={goBack}
              ariaLabel="Go Back"
            >
              <FiChevronLeft />
            </IconButton>
            <SocialMediaButtons shareLinks={shareLinks} isMobile={true} />
          </Flex>
        )}
        {/* <SocialMediaButtons inView={inView} /> */}
        {/* Header - Back Button & Social Media Share Buttons */}
        {/* Helper Text */}
        {/* Heading */}
        {/* Blog Cover Image */}
        <Category>
          {randomData.series}

          {isMobile && (
            <>
              &nbsp;•&nbsp;{randomData.date} &nbsp;•&nbsp;{" "}
              {randomData.formattedTime} read
            </>
          )}
        </Category>
        <BlogTitle>{randomData.title}</BlogTitle>
        <SubTitleContainer spaceBetween>
          <Flex>
            <StaticImage
              src="../../images/me.jpeg"
              alt="Parmeet Singh"
              width={36}
              height={36}
              imgStyle={{
                borderRadius: "50%",
              }}
              placeholder="none"
            />
            <Name>{name}</Name>
          </Flex>
          {!isMobile && (
            <Flex>
              <BlogHelperText>
                {randomData.date} &nbsp;•&nbsp; {randomData.formattedTime} read
              </BlogHelperText>
            </Flex>
          )}
        </SubTitleContainer>
        {/* <Flex spaceBetween> */}
        {/* <BlogHelperText>{randomData.date}</BlogHelperText> */}
        {/* <span>Social Media Buttons</span> */}
        {/* </Flex> */}

        {isVideo(cover_src) ? (
          <Video
            autoPlay
            loop
            muted
            // ref={ref}
            src={cover_src}
          ></Video>
        ) : (
          <Image src={cover_src} alt={title} />
        )}

        <BlogContentContainer>
          <MDXRenderer>{body}</MDXRenderer>
        </BlogContentContainer>
        <BlogFooter>
          <ShareBlogFooter spaceBetween>
            <ShareFooterText>Share</ShareFooterText>
            <Flex>
              <IconButton
                href={shareLinks.twitter}
                iconColor="#000"
                bgColor="#888"
                target="_blank"
                ariaLabel="Share on Twitter"
              >
                <FiTwitter />
              </IconButton>
              <IconButton
                href={shareLinks.facebook}
                iconColor="#000"
                bgColor="#888"
                target="_blank"
                ariaLabel="Share on Facebook"
              >
                <FiFacebook />
              </IconButton>{" "}
              <IconButton
                href={shareLinks.linkedin}
                iconColor="#000"
                bgColor="#888"
                target="_blank"
                ariaLabel="Share on Linkedin"
              >
                <FiLinkedin />
              </IconButton>{" "}
              {hasClipboard && (
                <IconButton
                  iconColor="#000"
                  bgColor="#888"
                  onClick={() => {
                    copyLink(shareLinks.blogLink);
                    toast.success("Link copied!");
                  }}
                  ariaLabel="Copy to Clipboard"
                >
                  <FiLink />
                </IconButton>
              )}
            </Flex>
          </ShareBlogFooter>
          <AuthorBlogFooter>
            {isMobile ? (
              <StaticImage
                src="../../images/me.jpeg"
                alt="Parmeet Singh"
                width={70}
                height={70}
                imgStyle={{
                  borderRadius: "50%",
                }}
                placeholder="none"
              />
            ) : (
              <StaticImage
                src="../../images/me.jpeg"
                alt="Parmeet Singh"
                width={100}
                height={100}
                imgStyle={{
                  borderRadius: "50%",
                }}
                placeholder="none"
              />
            )}
            <AuthorBlogFooterContent column alignTop alignLeft spaceBetween>
              <AuthorLabel>Written By</AuthorLabel>
              <AuthorName>{name}</AuthorName>
              <AuthorDescription>
                I craft scalable products with modern web technologies.
              </AuthorDescription>
            </AuthorBlogFooterContent>
          </AuthorBlogFooter>
        </BlogFooter>
        {/* Tags */}
        {/* Social Media Buttons */}
        {/* Related Articles */}
      </BlogContainer>
    </Layout>
  );
};

export default Blog;

/* {/* Scroll Progress }
{/* Go to Top Button } */
